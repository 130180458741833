import React from 'react';
import { Link as RLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

/**
 * Wraps the Material UI Link component for routing
 *
 * @param {object} props General link props for Material UI `Link` component
 */
export const InternalLink = React.forwardRef((props, ref) => {
  return (
    <MuiLink
      ref={ref}
      style={{ textDecoration: 'none' }}
      noWrap
      component={RLink}
      {...props}
    />
  );
});
