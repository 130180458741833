import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const breakpointValues = {
  breakpoints: createBreakpoints({
    keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xxs: 0,
      xs: 375,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  }),
};
