import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(6),
    },
    background: '#FAFAFA',
    wordBreak: 'break-word',
    maxHeight: `calc(100vh - ${theme.overrides.MuiToolbar.dense.height})`,
    overflowY: 'auto',
    width: '100%',
  },
  container: {
    margin: '0 auto',
    maxWidth: '676px',
  },
  date: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },
  author: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    marginTop: '4px',
  },
  content: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    marginTop: '20px',
  },
}));
