import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const YemenFlag = () => {
  return (
    <SvgIcon viewBox="0 0 24 25">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        d="M0 0h24v25H0z"
      />
      <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M0 0h24v17H0z" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#ce1126"
        d="M0 0h24v8H0z"
      />
    </SvgIcon>
  );
};
