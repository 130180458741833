import { createMuiTheme } from '@material-ui/core/styles';
import { regular, mono } from 'styles/fonts';
import { breakpointValues } from 'styles/breakpoints';

const theme = createMuiTheme(
  {
    palette: {
      // todo - we should manage colors here. Use './colors'
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#FBEE33',
      },
    },
    typography: {
      fontFamily: `${regular}, ${mono}`,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [regular, mono],
        },
      },
      MuiFormControl: {
        root: {
          paddingBottom: '1rem',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
          color: '#000000DE',
          fontSize: '13px',
          lineHeight: 1.2,
          padding: '10px',
        },
        arrow: {
          color: '#FFFFFF',
        },
      },
      MuiToolbar: {
        dense: {
          height: '58px',
        },
      },
      MuiAutocomplete: {
        tag: { marginLeft: 0, marginRight: '6px' },
      },
    },
    props: {
      MuiSelect: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        },
      },
    },
  },
  breakpointValues
);

export default theme;
