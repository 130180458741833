import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { useFluentLanguages } from 'locale/LocaleProvider';
import FluentLanguagesDialog from './FluentLanguagesDialog';

export const UserSettingsModal = ({ openModal, handleCloseModal }) => {
  const { fluentLanguages, setFluentLanguages } = useFluentLanguages();
  const intl = useIntl();

  const availableLanguages = [
    { label: intl.formatMessage({ id: 'english' }), key: 'en' },
    { label: intl.formatMessage({ id: 'arabic' }), key: 'ar' },
    { label: intl.formatMessage({ id: 'french' }), key: 'fr' },
    { label: intl.formatMessage({ id: 'russian' }), key: 'ru' },
    { label: intl.formatMessage({ id: 'spanish' }), key: 'sp' },
    { label: intl.formatMessage({ id: 'german' }), key: 'ge' },
    { label: intl.formatMessage({ id: 'farsi' }), key: 'fa' },
  ];

  const toggleLanguage = (languageKey) => {
    const languages = new Set(fluentLanguages);
    // `Set` objects will return true on delete in conditional below. If delete fails (language key isn't in array), add the language key.
    if (!languages.delete(languageKey)) languages.add(languageKey);
    setFluentLanguages([...languages]);
  };

  const handleChange = (event) => toggleLanguage(event.target.name);

  return (
    <FluentLanguagesDialog
      openModal={openModal}
      handleCloseModal={handleCloseModal}
    >
      <FormControl component="fieldset">
        <Grid container spacing={1}>
          {availableLanguages.map(({ label, key }) => {
            return (
              <Grid key={key} item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fluentLanguages.includes(key)}
                      onChange={handleChange}
                      name={key}
                      color="primary"
                    />
                  }
                  label={label}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormControl>
    </FluentLanguagesDialog>
  );
};

UserSettingsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
