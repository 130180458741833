import React from 'react';
import { formatIsoDate } from 'utils';
import { useStyles } from './PostStyles';

export default function Post({ post }) {
  const classes = useStyles();
  if (!post) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.date}>{formatIsoDate(post.created)}</div>
        <div className={classes.title}>{post.title}</div>
        <div className={classes.author}>{post.sourceName}</div>
        <div className={classes.author}>{post.author}</div>
        <div className={classes.content}>{post.content}</div>
      </div>
    </div>
  );
}
