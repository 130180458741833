import { makeStyles } from '@material-ui/core/styles';

export const useSelectStyles = makeStyles(() => ({
  icon: {
    marginRight: 0,
  },
  select: {
    paddingLeft: 0,
  },
}));

export const useInputLabelStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
  },
  shrink: {
    paddingLeft: 0,
  },
}));

export const useAutocompleteStyles = makeStyles(() => ({
  input: {
    paddingLeft: 0,
  },
  endAdornment: {
    marginRight: 0,
    top: 'calc(50% - 1rem)',
    right: 0,
    position: 'absolute',
  },
}));

export const useButtonStyles = makeStyles(() => ({
  contained: {
    padding: '4px 10px',
  },
  outlined: {
    color: '#536063',
    padding: '3px 10px',
  },
  label: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',

    letterSpacing: ' 0.4px',
    textTransform: 'uppercase',
  },
}));
