import {
  ADD_INCIDENT,
  REMOVE_INCIDENT,
  UPDATE_INCIDENT,
} from './incidentActionTypes';

export const addIncident = (incident) => {
  return {
    type: ADD_INCIDENT,
    payload: incident,
  };
};

export const removeIncident = (index) => {
  return {
    type: REMOVE_INCIDENT,
    payload: index,
  };
};

export const updateIncident = (index, updatedIncident) => {
  return {
    type: UPDATE_INCIDENT,
    payload: { index, updatedIncident },
  };
};
