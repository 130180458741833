import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export default function FluentLanguagesDialog({
  openModal,
  handleCloseModal,
  children,
}) {
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="lang-dialog-title"
    >
      <DialogTitle id="lang-dialog-title">
        <FormattedMessage
          id="fluentLanguages"
          defaultMessage="Fluent Languages"
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          <FormattedMessage id="close" defaultMessage="CLOSE" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
