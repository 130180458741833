import { gql } from '@apollo/client';

const GET_LOCATIONS = gql`
  query getLocations($input: String!) {
    getLocations(input: $input) {
      lat
      lon
      location
      adminLevel0
      adminLevel1
      adminLevel2
      adminLevel3
    }
  }
`;

export default GET_LOCATIONS;
