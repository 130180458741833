import { useMutation } from '@apollo/client';
import SUBMIT_POST_ANALYSIS from 'mutations/submitPostAnalysis';
import GET_POSTS from 'queries/getPosts';

import { useFluentLanguages } from 'locale/LocaleProvider';
import { transformIncidents } from './utils';

export const usePostMutation = () => {
  const { fluentLanguages } = useFluentLanguages();

  const [submitPostAnalysis, { error, loading }] = useMutation(
    SUBMIT_POST_ANALYSIS,
    {
      refetchQueries: [
        { query: GET_POSTS, variables: { languages: fluentLanguages } },
      ],
    }
  );

  const postMutation = (incidents) => {
    const transformedAnswers = transformIncidents(incidents);
    return submitPostAnalysis({
      variables: { answers: transformedAnswers },
    });
  };

  return { postMutation, loading, error };
};
