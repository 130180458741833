import {
  ADD_INCIDENT,
  REMOVE_INCIDENT,
  UPDATE_INCIDENT,
} from '../actions/incidentActionTypes';

export default function incidentReducer(incidents, action) {
  const { type } = action;
  switch (type) {
    case ADD_INCIDENT: {
      const incidentToAdd = action.payload;
      return [...incidents, incidentToAdd];
    }
    case REMOVE_INCIDENT: {
      const indexToRemove = action.payload;
      return incidents.filter((_, index) => index !== indexToRemove);
    }
    case UPDATE_INCIDENT: {
      const { index, updatedIncident } = action.payload;
      return incidents.map((incident, i) => {
        if (i === index) {
          return {
            ...incident,
            ...updatedIncident,
          };
        }
        return incident;
      });
    }
    default:
      return incidents;
  }
}
