import React from 'react';
import { useIntl } from 'react-intl';
import useThemeDirection from 'hooks/useThemeDirection';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useButtonStyles } from '../../FormStyles';

export default function RightButton({
  isDisabled,
  classes,
  onClick = (f) => f,
}) {
  const buttonClasses = useButtonStyles();
  const intl = useIntl();
  const { isRtl } = useThemeDirection();

  const rightButtonClasses = {
    outlined: buttonClasses.outlined,
    label: buttonClasses.label,
    disabled: classes?.disabled,
    contained: clsx(buttonClasses.contained, classes?.contained),
  };

  return (
    <Button
      aria-label="next"
      onClick={onClick}
      variant="outlined"
      disabled={isDisabled}
      classes={rightButtonClasses}
      startIcon={isRtl ? <ChevronLeftIcon /> : null}
      endIcon={isRtl ? null : <ChevronRightIcon />}
    >
      {intl.formatMessage({ id: 'next' })}
    </Button>
  );
}
