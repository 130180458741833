export const formatIsoDate = (date = '') => {
  return date.substring(0, 10);
};

const rtlLanguageCodes = [
  'ar',
  'arc',
  'dv',
  'fa',
  'ha',
  'he',
  'khw',
  'ks',
  'ku',
  'ps',
  'ur',
  'uz_AF',
  'yi',
];

export const isRtlLanguage = (code) => {
  const response = rtlLanguageCodes.includes(code) ? true : false;
  return response;
};
