import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';

import TodayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';

import { LocaleMuiPickersUtilsProvider } from 'components/helpers';

export const DateTimeInput = ({
  name,
  value,
  onChange,
  error,
  dateLabel = 'Date',
  timeLabel = 'Time',
  timeLabelSuffix = '',
}) => {
  const intl = useIntl();

  const sharedProps = {
    fullWidth: true,
    clearable: true,
    autoOk: true,
    value,
    onChange,
    error,
    helperText: error ? error : null,
    okLabel: intl.formatMessage({ id: 'set' }),
    cancelLabel: intl.formatMessage({ id: 'cancel' }),
    clearLabel: intl.formatMessage({ id: 'clear' }),
  };

  return (
    <LocaleMuiPickersUtilsProvider locale={intl.locale}>
      <DatePicker
        {...sharedProps}
        {...getInputProps(name, 'date')}
        label={dateLabel}
        disableFuture
      />
      <TimePicker
        {...sharedProps}
        {...getInputProps(name, 'time')}
        label={`${timeLabel} ${timeLabelSuffix}`}
      />
    </LocaleMuiPickersUtilsProvider>
  );
};

DateTimeInput.propTypes = {
  // The form input name
  name: PropTypes.string.isRequired,
  // The current date value
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  // Setter for the date
  onChange: PropTypes.func.isRequired,
  // Error to display
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // Label for the date field
  dateLabel: PropTypes.string,
  // Label for the time field
  timeLabel: PropTypes.string,
  // Text to add to the label, such as Timezone
  timeLabelSuffix: PropTypes.string,
};

// Private Components
const getInputProps = (name, type) => {
  const htmlFor = `${name}-${type}-input`;
  return {
    InputProps: {
      id: htmlFor,
      name: htmlFor,
      endAdornment: (
        <Box px={0}>{type === 'time' ? <ScheduleIcon /> : <TodayIcon />}</Box>
      ),
    },
    InputLabelProps: { htmlFor },
  };
};
