export const RELEVANCE_OPTIONS = [
  { id: 'YES', translateId: 'yes' },
  { id: 'NO', translateId: 'no' },
  {
    id: 'NOT_SURE',
    translateId: 'notSure',
  },
  {
    id: 'NOT_MY_LANGUAGE',
    translateId: 'notMyLanguage',
  },
];
