import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';

import { useQuestions } from 'components/QuestionsProvider';
import { useInputLabelStyles, useSelectStyles } from '../../FormStyles';

export default function GeographicQuestion({ value, onChange, isRequired }) {
  const inputLabelclasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();
  const { questions } = useQuestions();
  const { text, options } = questions.incidents.items.geographic.dropdown;

  if (options.length < 2) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <InputLabel
        required={isRequired}
        id="geographic-select-label"
        classes={inputLabelclasses}
      >
        {text}
      </InputLabel>
      <Select
        labelId="geographic-select-label"
        classes={selectClasses}
        value={value}
        data-testid="geographic-select"
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((theaterOption) => (
          <MenuItem key={theaterOption.id} value={theaterOption.id}>
            {theaterOption.text}
          </MenuItem>
        ))}
      </Select>
      {isRequired && <FormHelperText>*Required</FormHelperText>}
    </FormControl>
  );
}
