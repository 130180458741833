import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useQuestions } from 'components/QuestionsProvider';
import { useInputLabelStyles, useSelectStyles } from '../FormStyles';

export default function RelevanceForm({ value, onChangeValue }) {
  const inputLabelclasses = useInputLabelStyles();
  const selectClasses = useSelectStyles();

  const { questions } = useQuestions();
  const { text, options } = questions.relevance.dropdown;

  return (
    <FormControl fullWidth>
      <InputLabel classes={inputLabelclasses} id="relevance-select-label">
        {text}
      </InputLabel>

      <Select
        labelId="relevance-select-label"
        data-testid="relevance-select"
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        classes={selectClasses}
      >
        {options.map((relevanceOption) => (
          <MenuItem key={relevanceOption.id} value={relevanceOption.id}>
            {relevanceOption.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
