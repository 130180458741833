import React from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { StyledFab } from './FacebookFormStyles';
import { Save } from 'styles/assets/icons';
import { DateTimeInput } from 'components/helpers';

export const FacebookForm = ({ handleFacebookPost }) => {
  const intl = useIntl();

  return (
    <Box px={5} py={3} width={{ xs: '100vw', md: '50vw' }}>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          created: '',
          recordUrl: '',
          sourceName: '',
          language: '',
          content: '',
          sourceType: '',
          sourceUrl: '',
        }}
        validate={(values) => {
          const errors = {};
          if (
            !values.created ||
            Number.isNaN(new Date(values.created).getTime()) // check for if valid date
          ) {
            errors.created = intl.formatMessage({ id: 'createDateTimeError' });
          }
          if (!values.sourceUrl) {
            // TODO - This should validate the URL format
            errors.sourceUrl = intl.formatMessage({
              id: 'sourceUrlError',
            });
          }
          if (!values.recordUrl) {
            errors.recordUrl = intl.formatMessage({
              id: 'recordUrlError',
            });
          }
          if (!values.sourceName) {
            errors.sourceName = intl.formatMessage({
              id: 'sourceNameError',
            });
          }
          if (!values.content) {
            errors.content = intl.formatMessage({ id: 'contentError' });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          try {
            setSubmitting(true);
            const post = {
              created: values.created,
              recordType: 'post',
              language: values.language,
              content: values.content,
              fetchedTime: new Date().toISOString(),
              sourceName: values.sourceName,
              sourceType: values.sourceType,
              sourceUrl: values.sourceUrl,
              recordUrl: values.recordUrl,
            };
            handleFacebookPost(post);
          } catch (e) {
            setSubmitting(false);
          }
          resetForm();
          setSubmitting(false);
          setFieldValue('created', '');
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => {
          const setCreatedDate = (date) =>
            setFieldValue('created', date?.toISOString());
          const createdDate = values.created ? values.created : null;

          return (
            <form onSubmit={handleSubmit}>
              <DateTimeInput
                name="created"
                value={createdDate}
                onChange={setCreatedDate}
                dateLabel={intl.formatMessage({ id: 'createDate' })}
                timeLabel={intl.formatMessage({ id: 'createTime' })}
                error={
                  errors.created && touched.created ? errors.created : null
                }
              />
              <TextField
                fullWidth
                required
                id="recordUrl"
                aria-label="recordUrl"
                label={intl.formatMessage({ id: 'postUrl' })}
                value={values.recordUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.recordUrl && touched.recordUrl}
                helperText={touched.recordUrl && errors.recordUrl}
              />
              <FormControl fullWidth>
                <InputLabel htmlFor="language">
                  {intl.formatMessage({ id: 'language' })}
                </InputLabel>
                <Select
                  name="language"
                  value={values.language}
                  onChange={handleChange}
                  onBlur={(event) => {
                    event.target.name = 'language';
                    handleBlur(event);
                  }}
                  id="language"
                >
                  <MenuItem value="en">
                    {intl.formatMessage({ id: 'english' })}
                  </MenuItem>
                  <MenuItem value="ar">
                    {intl.formatMessage({ id: 'arabic' })}
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                required
                id="sourceName"
                aria-label="sourceName"
                label={intl.formatMessage({ id: 'sourceName' })}
                value={values.sourceName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.sourceName && touched.sourceName}
                helperText={touched.sourceName && errors.sourceName}
              />
              <TextField
                fullWidth
                required
                id="sourceUrl"
                aria-label="sourceUrl"
                label={intl.formatMessage({ id: 'sourceUrl' })}
                value={values.sourceUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.sourceUrl && touched.sourceUrl}
                helperText={touched.sourceUrl && errors.sourceUrl}
              />

              <FormControl fullWidth>
                <InputLabel htmlFor="sourceType">
                  {intl.formatMessage({ id: 'sourceType' })}
                </InputLabel>
                <Select
                  value={values.sourceType}
                  name="sourceType"
                  onChange={handleChange}
                  onBlur={(event) => {
                    event.target.name = 'sourceType';
                    handleBlur(event);
                  }}
                  id="sourceType"
                  label={intl.formatMessage({ id: 'sourceType' })}
                >
                  <MenuItem key="individual" value="individual">
                    {intl.formatMessage({ id: 'individual' })}
                  </MenuItem>
                  <MenuItem key="group" value="group">
                    {intl.formatMessage({ id: 'group' })}
                  </MenuItem>
                  <MenuItem key="event" value="event">
                    {intl.formatMessage({ id: 'event' })}
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                required
                id="content"
                aria-label="content"
                label={intl.formatMessage({ id: 'content' })}
                multiline
                value={values.content}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.content && touched.content}
                helperText={touched.content && errors.content}
              />
              <br />
              <br />
              <p>{intl.formatMessage({ id: 'requiredField' })}</p>

              <StyledFab
                disabled={isSubmitting}
                type="button"
                id="submit-form"
                aria-label="submit-form"
                variant="round"
                onClick={handleSubmit}
              >
                {isSubmitting ? <CircularProgress /> : <Save />}
              </StyledFab>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
