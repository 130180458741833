import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocale } from 'locale/LocaleProvider';

export const useBuildDropdownOptions = () => {
  const intl = useIntl();
  const { locale } = useLocale();

  const buildObjectOptions = useCallback(
    (options, translateKey = 'translateId', ownTranslation) => {
      return options.map((item) => ({
        id: item.id,
        text: ownTranslation
          ? item[translateKey][locale]
          : intl.formatMessage({ id: item[translateKey] }),
      }));
    },
    [intl, locale]
  );

  const buildDropdown = useCallback(
    (options, label, translateKey, ownTranslation) => ({
      options: buildObjectOptions(options, translateKey, ownTranslation),
      text: intl.formatMessage({ id: label }),
    }),
    [buildObjectOptions, intl]
  );

  return { buildDropdown, buildObjectOptions };
};
