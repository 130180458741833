import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';

const customFetch = async (uri, options) => {
  try {
    const session = await Auth.currentSession();
    const idToken = session?.idToken?.jwtToken;
    if (idToken) options.headers.Authorization = idToken;
  } catch (e) {
    Sentry.captureException(e);
    console.error('Authentication Error.'); // eslint-disable-line no-console
  }

  return fetch(uri, options);
};

const getClient = (uri) =>
  new ApolloClient({
    link: new HttpLink({ uri, fetch: customFetch }),
    cache: new InMemoryCache(),
  });

export const BlueJavaClient = getClient(
  process.env.REACT_APP_BLUE_JAVA_GRAPHQL_URI
);

export const LocationClient = getClient(
  process.env.REACT_APP_LOCATION_GRAPHQL_URI
);
