import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import LocationSearch from 'components/LocationSearch/LocationSearch';
import { sortLocationData } from './utils';
import { LocationClient } from 'services/apollo';
import GET_LOCATIONS from 'queries/getLocations';

export default function LocationQuestion({
  values,
  onChange,
  isRequired,
  theater,
}) {
  const [isValidDebouncedSearchTerm, setIsValidDebouncedSearchTerm] = useState(
    false
  );

  const queryOptionsPerEnv = {
    true: { fetchPolicy: 'no-cache' },
    false: { client: LocationClient },
  };

  const [getLocations, { loading, data: locationData, error }] = useLazyQuery(
    GET_LOCATIONS,
    queryOptionsPerEnv[process.env.REACT_APP_TESTING_MODE === 'true']
  );

  return (
    <>
      <LocationSearch
        locationOptions={
          isValidDebouncedSearchTerm ? getLocationOptions() : values
        }
        loading={loading}
        error={error}
        onLocationsChange={onChange}
        onDebouncedSearchTermChange={handleDebouncedSearchTermChange}
        chosenLocations={values}
        isRequired={isRequired}
        theater={theater}
      />
    </>
  );

  function handleDebouncedSearchTermChange(debouncedSearchTerm) {
    setIsValidDebouncedSearchTerm(false);
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setIsValidDebouncedSearchTerm(true);
      getLocations({
        variables: {
          input: debouncedSearchTerm.toString().replace(/[^0-9a-zأ-ي]/gi, ''),
        },
      });
    }
  }

  function getLocationOptions() {
    return [...values, ...sortLocationData(locationData?.getLocations)];
  }
}
