import React from 'react';
import { useIntl } from 'react-intl';
import useThemeDirection from 'hooks/useThemeDirection';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useButtonStyles } from '../../FormStyles';

export default function LeftButton({ isDisabled, onClick = (f) => f }) {
  const buttonClasses = useButtonStyles();
  const intl = useIntl();
  const { isRtl } = useThemeDirection();

  const leftButtonClasses = {
    outlined: buttonClasses.outlined,
    label: buttonClasses.label,
    contained: buttonClasses.contained,
  };

  return (
    <Button
      aria-label="previous"
      onClick={onClick}
      variant="outlined"
      disabled={isDisabled}
      classes={leftButtonClasses}
      startIcon={isRtl ? null : <ChevronLeftIcon />}
      endIcon={isRtl ? <ChevronRightIcon /> : null}
    >
      {intl.formatMessage({ id: 'previous' })}
    </Button>
  );
}
