import { gql } from '@apollo/client';

const GET_POSTS = gql`
  query getPosts($languages: [String!]) {
    fetchPostsForLanguages(languages: $languages) {
      id
      title
      created
      blueJavaSource
      sourceName
      language
      content
      sourceUrl
      recordType
      recordUrl
      blueJavaCampaign
    }
  }
`;

export default GET_POSTS;
