import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AttrIconLarge = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 29 31">
      <path
        d="M14.5 30.0471C22.5081 30.0471 29 23.534 29 15.4996C29 7.46522 22.5081 0.95207 14.5 0.95207C6.49187 0.95207 0 7.46522 0 15.4996C0 23.534 6.49187 30.0471 14.5 30.0471Z"
        fill="#FBEE33"
      />
      <path
        d="M16.3978 9.03505H19.2806V21.461H16.3978V20.1644C15.9415 20.7022 15.3723 21.1318 14.7309 21.4226C14.0895 21.7134 13.3918 21.8581 12.6881 21.8463C8.86538 21.8463 6.68744 18.673 6.68744 15.2321C6.68744 11.1929 9.45279 8.71317 12.7197 8.71317C13.428 8.69795 14.1299 8.85004 14.7689 9.15716C15.4078 9.46428 15.9658 9.91783 16.3978 10.4812V9.03505ZM9.61997 15.2911C9.61997 17.449 10.9755 19.2487 13.0631 19.2487C14.8705 19.2487 16.565 17.9204 16.565 15.3228C16.565 12.6028 14.8886 11.3108 13.0631 11.3108C10.9846 11.3108 9.62901 13.0788 9.62901 15.2911H9.61997Z"
        fill="#231F20"
      />
      <path
        d="M23.1349 19.2487V21.461H20.8757V19.2487H23.1349Z"
        fill="#231F20"
      />
    </SvgIcon>
  );
};
