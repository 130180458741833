import React from 'react';

import GeographicQuestion from './GeographicQuestion';
import TypeQuestion from './TypeQuestion';
import LocationQuestion from './LocationQuestion';
import DateTimeQuestion from './DateTimeQuestion';

export default function IncidentForm({
  incident,
  renderHeader = () => null,
  onChange = (f) => f,
  ...props
}) {
  return (
    <div {...props}>
      {renderHeader()}
      <TypeQuestion
        isRequired={true}
        values={incident.incidentType}
        onChange={(value) => handleFieldChange({ incidentType: value })}
      />
      <GeographicQuestion
        value={incident.theater}
        onChange={(value) => {
          let change = { theater: value };
          handleFieldChange(change);
        }}
      />
      <LocationQuestion
        values={incident.locations}
        theater={incident.theater}
        onChange={(value) => handleFieldChange({ locations: value })}
        isRequired={true}
      />
      <DateTimeQuestion
        incidentTime={incident.incidentTime}
        theater={incident.theater}
        onChange={(value) => handleFieldChange({ incidentTime: value })}
      />
    </div>
  );
  function handleFieldChange(change) {
    onChange({ ...incident, ...change });
  }
}
