import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import { formatIsoDate, isRtlLanguage } from 'utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
  StyledInternalLink,
  StyledListItemText,
  StyledToggleButton,
} from './PostListItemStyles';

export const PostListItem = ({ post, setPostsSelected, postsSelected }) => {
  const direction = isRtlLanguage(post.language) ? 'rtl' : 'ltr';

  const header = `${formatIsoDate(post.created)}`;
  const title = !post.title ? post.content : post.title;

  const onChange = () => {
    const selectedSet = new Set(postsSelected);
    if (!selectedSet.delete(post.id)) selectedSet.add(post.id);
    setPostsSelected([...selectedSet]);
  };
  return (
    <ListItem>
      <StyledToggleButton
        data-testid="toggle-button"
        value="check"
        selected={postsSelected.includes(post.id)}
        onChange={onChange}
      >
        {postsSelected.includes(post.id) ? (
          <CheckCircleIcon
            style={{ color: 'black', backgroundColor: 'white' }}
          />
        ) : (
          <RadioButtonUncheckedIcon style={{ color: 'black' }} />
        )}
      </StyledToggleButton>

      <StyledInternalLink
        data-testid="post-link"
        onClick={() => setPostsSelected([])} // removes postsSelected and changes action bar to navbar
        to={{ pathname: `/post/${post.id}`, state: { post } }}
      >
        <StyledListItemText
          dir={direction}
          primary={header}
          secondary={title}
        />
      </StyledInternalLink>
    </ListItem>
  );
};

PostListItem.propTypes = {
  post: PropTypes.object.isRequired,
};
