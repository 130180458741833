import React from 'react';
import _ from 'lodash';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl, FormattedMessage } from 'react-intl';

import Toaster from 'utils/toasterConfig';
import { useStyles, MenuToolbar, Heading } from './multiselectActionBarStyles';
import { usePostMutation } from 'hooks/usePostMutation/usePostMutation';
import { RELEVANCE_OPTIONS } from 'constants/index';

export const MultiselectActionBar = ({ postsSelected, setPostsSelected }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { postMutation } = usePostMutation();

  const { NO } = _.keyBy(RELEVANCE_OPTIONS, 'id');

  const postAnalysisSubmit = async () => {
    try {
      await postMutation(
        postsSelected.map((postId) => ({
          postId,
          relevant: NO.id,
        }))
      );
      Toaster.success(
        `${postsSelected.length} ${intl.formatMessage({
          id: 'toasterMultiSelectSuccess',
        })}`
      );
      setPostsSelected([]);
    } catch (e) {
      Toaster.error(intl.formatMessage({ id: 'toasterError' }));
    }
  };
  return (
    <MenuToolbar variant="dense">
      <IconButton data-testid="clearPosts" onClick={() => setPostsSelected([])}>
        <CloseIcon type="button" style={{ color: 'white' }} />
      </IconButton>
      <Box m={2} className={classes.countContainer}>
        <Heading variant="h6" className={classes.selectedCount}>
          {postsSelected.length}
        </Heading>
      </Box>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button
          data-testid="submitPosts"
          onClick={postAnalysisSubmit}
          className={classes.submitIrrelevant}
        >
          <FormattedMessage
            id="saveIrrelevant"
            defaultMessage="save as irrelevant"
          />
        </Button>
      </Grid>
    </MenuToolbar>
  );
};
