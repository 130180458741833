import _ from 'lodash';

// used to concatenate the various parts of Locations
export const determineLocationText = (locObj) => {
  const { location, adminLevel1, adminLevel2 } = locObj ?? {};

  return _.chain([location, adminLevel2, adminLevel1])
    .filter((v) => !_.isEmpty(v))
    .join(', ')
    .value();
};
