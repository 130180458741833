import React from 'react';
import ReactDOM from 'react-dom';
import 'toastr/build/toastr.min.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { initAmplify } from 'services/amplify';

// 3rd party app used to monitor errors. Setup for prod and staging
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1,
});

initAmplify();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
