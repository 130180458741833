import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TODO this is a placeholder so we could submit a page for google auth
export const PrivacyPolicy = () => {
  return (
    <Box p={6}>
      <Typography variant="h3" component="h1">
        <FormattedMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
      </Typography>
    </Box>
  );
};
