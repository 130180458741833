import { useState, useRef, useCallback } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useDrawerState = () => {
  const backdropRef = useRef();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isFullScreen, setIsFullScreen] = useState(false);

  const fullScreenVariants = { true: 'persistent', false: 'temporary' };

  const toolbarHeight = parseInt(theme.overrides.MuiToolbar.dense.height);

  const checkIfFullScreen = useCallback(
    (el) => {
      const hasScroll = el?.scrollHeight > el?.clientHeight;
      const maxHeight = window.document.body.clientHeight - 3 * toolbarHeight;

      const screenValue = isOpen && (hasScroll || el?.clientHeight > maxHeight);
      setIsFullScreen(screenValue);
    },
    [isOpen, toolbarHeight]
  );

  const closeDrawerOnBackdropTouch = useCallback((e) => {
    setIsOpen(e.target !== backdropRef.current);
  }, []);

  return {
    backdropRef,
    closeDrawerOnBackdropTouch,
    isOpen,
    shouldForceOpen: !isMobile || isOpen,
    setIsOpen,
    isMobile,
    variant: isMobile ? fullScreenVariants[isFullScreen] : 'permanent',
    isFullScreen,
    setIsFullScreen,
    checkIfFullScreen,
  };
};
