import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'App.css';
import Routes from 'Routes';
import { BlueJavaClient } from 'services/apollo';
import { LocaleProvider } from 'locale';
import BlueJavaThemeProvider from 'styles/ThemeProvider';
import QuestionsProvider from 'components/QuestionsProvider';

const App = () => {
  return (
    <LocaleProvider>
      <ApolloProvider client={BlueJavaClient}>
        <BlueJavaThemeProvider>
          <QuestionsProvider>
            <Router>
              <Routes />
            </Router>
          </QuestionsProvider>
        </BlueJavaThemeProvider>
      </ApolloProvider>
    </LocaleProvider>
  );
};

export default App;
