import { format } from 'date-fns-tz';
import _ from 'lodash';

/**
 * Gets the short string for a timezone
 *
 * @param {*} timeZone IANA timezone string
 */
export const getOffsetString = (timeZone, date = null) => {
  const short = format(new Date(date), 'XXX', { timeZone });
  // Workaround, because date-fns-tz format returns
  // ±hh:mm for most zones, but Z for UTC
  return short === 'Z' ? '+00:00' : short;
};

/**
 * Converts the separate date/time/tz fields to a ISO-valid string
 *
 * yyyy-mm-dd
 * yyyy-mm-dd hh:mm ±hh:mm
 */
export const getIncidentTimeString = ({ date, time, timezone }) => {
  if (!date) return null;
  const dateString = format(date, 'yyyy-MM-dd');
  const timeString = _.isDate(time) ? time.toTimeString().slice(0, 5) : time;
  const tzString = getOffsetString(timezone, date);

  return time ? `${dateString} ${timeString} ${tzString}` : dateString;
};
