import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  wizardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  wizardHeaderLabel: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  wizardHeaderProgress: {
    fontFamily: 'Roboto Mono',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));
