import React from 'react';
import { useQuestions } from 'components/QuestionsProvider';
import { useStyles } from './WizardBottomControlsStyles';

import LeftButton from './LeftButton';
import RightButton from './RightButton';
import SaveButton from './SaveButton';

import { isNotRelevant } from '../utils';

export default function WizardBottomControls({
  currentStep,
  onChangeCurrentStep,
  relevanceAnswer,
  canISaveIncidents,
  onSaveIncidents,
  loading,
}) {
  const classes = useStyles();
  const { questions } = useQuestions();

  return (
    <div className={classes.root}>
      <LeftButton
        onClick={() => onChangeCurrentStep(questions.relevance)}
        isDisabled={currentStep.progress === questions.relevance.progress}
      />

      {isLastStep() ? (
        <SaveButton
          onClick={onSaveIncidents}
          isDisabled={isSaveButtonDisabled()}
          loading={loading}
        />
      ) : (
        <RightButton
          onClick={() => onChangeCurrentStep(questions.incidents)}
          isDisabled={!relevanceAnswer}
        />
      )}
    </div>
  );

  function isLastStep() {
    return (
      isNotRelevant(relevanceAnswer) ||
      currentStep.progress === questions.incidents.progress
    );
  }

  function isSaveButtonDisabled() {
    let isDisabled =
      loading ||
      (currentStep.progress === questions.incidents.progress
        ? !canISaveIncidents
        : !relevanceAnswer);

    return isDisabled;
  }
}
