import React from 'react';
import { PropTypes } from 'prop-types';
import format from 'date-fns/format';
import enLocale from 'date-fns/locale/en-GB';
import arLocale from 'date-fns/locale/ar-SA';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

/**
 * Wraps the MuiPickersUtilsProvider to support custom date formatting
 * by locale. Particularly, we are fixing the issue of truncated month
 * names not making sense in Arabic.
 *
 * Reference: https://material-ui-pickers.dev/guides/formats
 */
export const LocaleMuiPickersUtilsProvider = ({ locale, children }) => {
  const formLocale = locale === 'ar' ? arLocale : enLocale;

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={formLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMMM', { locale: this.locale });
  }
}

LocaleMuiPickersUtilsProvider.propTypes = {
  // Currently either "en" or "ar" to indicate English or Arabic
  locale: PropTypes.string,
  //  Child nodes of this component
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
