import _ from 'lodash';
import INCIDENT_TYPES from './db/incidentTypes.json';
import THEATER_OPTIONS from './db/theaterOptions.json';

const _pipe = (a, b) => (arg) => b(a(arg));
const pipe = (...ops) => ops.reduce(_pipe);

export default {
  getList,
  getIncidentTypeList,
  getTheaterList,
};

function getIncidentTypeList(criteria = {}) {
  return getList(INCIDENT_TYPES, criteria);
}

function getTheaterList(criteria = {}) {
  return getList(THEATER_OPTIONS, criteria);
}

function getList(list, criteria = {}) {
  const { filterRules, labelRules } = criteria;

  return pipe(
    buildFilterList(filterRules),
    buildLabelMapFunction(labelRules),
    omitInternalProperties
  )(list);
}

function buildFilterList(filterRules) {
  if (!filterRules) {
    return (list) => list;
  }
  const rules = filterRules ? filterRules.split(',') : [];
  return (list) =>
    list.filter((item) => {
      return rules.some((r) => item.belongsTo.includes(r));
    });
}

function buildLabelMapFunction(labelRules) {
  const rules = labelRules ? labelRules.split(',') : [];
  return (list) =>
    list.map((item) => {
      const labelRule = rules.reduce((result, r) => {
        if (item.label[r]) {
          return r;
        }
        return result;
      }, '');

      const label = item.label
        ? item.label[labelRule] || item.label.default
        : undefined;

      return {
        ...item,
        label,
      };
    });
}

function omitInternalProperties(list) {
  return list.map((item) => _.pick(item, ['id', 'label']));
}
