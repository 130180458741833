/* eslint-disable no-console */
import _ from 'lodash';
// sorts by level of detail received on location, then it is comma separated.
export const sortLocationData = (locationArray = []) => {
  const locations = [];
  const districts = [];
  const governorates = [];
  locationArray.forEach((loc) => {
    const { location, adminLevel2 } = loc;
    if (location) {
      locations.push(loc);
    } else if (adminLevel2) {
      districts.push(loc);
    } else {
      governorates.push(loc);
    }
  });

  return _.flatten([governorates, districts, locations]);
};
