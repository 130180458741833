import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

export const StyledFab = withStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))(Fab);
