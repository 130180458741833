import React from 'react';
import IconButton from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';

import IncidentHeader from '../IncidentHeader';
import IncidentForm from '../IncidentForm';
import { useStyles } from './IncidentFormsStyles';

export default function IncidentForms({
  incidents = [],
  renderLabelHeader = () => null,
  onChangeIncident = (f) => f,
  onDeleteIncident = (f) => f,
}) {
  const classes = useStyles();

  return incidents.map((incident, index) => (
    <div key={index}>
      <Box marginTop={2} />
      <IncidentForm
        data-testid={`incident-form-${index + 1}`}
        incident={incident}
        onChange={(updatedIncident) => onChangeIncident(index, updatedIncident)}
        renderHeader={() => {
          const incidentLabel = renderLabelHeader(index);
          return (
            <>
              <IncidentHeader
                label={incidentLabel}
                renderEndAdornment={() => (
                  <IconButton
                    className={classes.deleteButton}
                    color="rgba(0, 0, 0, 0.54)"
                    size="small"
                    aria-label="delete"
                    onClick={() => onDeleteIncident(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              />
              <Box marginTop="12px" />
            </>
          );
        }}
      />
    </div>
  ));
}
