import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Refresh = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        fill="none"
        d="M18.362 7.20021C16.1469 4.51708 12.4865 3.51212 9.21183 4.68801C5.93718 5.8639 3.75227 8.96783 3.75 12.4472V14.7002"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M6 18.1083C8.3199 20.5665 11.9046 21.3609 15.0459 20.113C18.1871 18.8651 20.2494 15.8273 20.25 12.4473V10.9473"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M0.75 11.6973L3.75 14.6973L6.75 11.6973"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M23.25 13.9473L20.25 10.9473L17.25 13.9473"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
