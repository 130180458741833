import React, { useMemo } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useAuthUser } from '@hala-systems/hala-auth';
import { userHasGroup, getUserGroups } from 'auth/permissions';

import { useLocale } from 'locale/LocaleProvider';
import configManagementService from 'services/configManagementService';
import { useBuildDropdownOptions } from 'hooks/useBuildDropdownOptions';
import { RELEVANCE_OPTIONS } from 'constants/index';
import QuestionsContext from './QuestionsContext';

export default function QuestionsProvider({ children }) {
  const intl = useIntl();
  const { locale } = useLocale();
  const { user } = useAuthUser();
  const { buildDropdown } = useBuildDropdownOptions();

  let incidentTypesCriteria = 'role:data-labeler';
  if (
    userHasGroup(user, 'role:observer') ||
    userHasGroup(user, 'role:data-labeler')
  ) {
    incidentTypesCriteria = getUserGroups(user)?.join();
  }

  const INCIDENT_TYPES = useMemo(() => {
    const list = configManagementService.getIncidentTypeList({
      filterRules: incidentTypesCriteria,
      labelRules: incidentTypesCriteria,
    });
    return _.orderBy(list, (item) => item.label[locale].toLowerCase());
  }, [incidentTypesCriteria, locale]);

  let theaterCriteria = 'region:yemen';
  if (
    userHasGroup(user, 'region:yemen') ||
    userHasGroup(user, 'region:syria')
  ) {
    theaterCriteria = getUserGroups(user)?.join();
  }

  const THEATER_OPTIONS = useMemo(() => {
    return configManagementService.getTheaterList({
      filterRules: theaterCriteria,
      labelRules: theaterCriteria,
    });
  }, [theaterCriteria]);

  const questions = useMemo(
    () => ({
      relevance: {
        progress: 1,
        header: intl.formatMessage({ id: 'relevanceHeader' }),
        dropdown: buildDropdown(RELEVANCE_OPTIONS, 'relevanceLabel'),
      },
      incidents: {
        progress: 2,
        header: intl.formatMessage({ id: 'incidentReport' }),
        items: {
          geographic: {
            dropdown: buildDropdown(
              THEATER_OPTIONS,
              'theaterLabel',
              'label',
              true
            ),
          },
          incidentType: {
            dropdown: buildDropdown(
              INCIDENT_TYPES,
              'incidentHeader',
              'label',
              true
            ),
          },
        },
      },
    }),
    [intl, buildDropdown, THEATER_OPTIONS, INCIDENT_TYPES]
  );

  return (
    <QuestionsContext.Provider value={questions}>
      {children}
    </QuestionsContext.Provider>
  );
}
