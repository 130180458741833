import React, { useState } from 'react';
import { Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { ProtectedRoute } from '@hala-systems/hala-auth';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { NavBar } from 'components/layout/nav';
// PAGES
import { Unauthorized } from 'components/pages/Unauthorized/Unauthorized';
import { PrivacyPolicy } from 'components/pages/PriavacyPolicy/PrivacyPolicy';
import { MultiselectActionBar } from 'components/layout/multiselectActionBar';
import AnalyzePostPage from './components/pages/AnalyzePostPage';

// CONTAINERS
import { PostListContainer } from 'containers/PostList';
import { FacebookFormContainer } from 'containers/FacebookForm';
import { userHasGroup } from 'auth/permissions';
import GoogleAnalytics from 'googleAnalytics';

const Routes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const hasGroupTest = (group) => (user) => userHasGroup(user, group);
  const [postsSelected, setPostsSelected] = useState([]);
  const location = useLocation();
  const showCloseIcon = matchPath(location.pathname, '/post/:id') && isMobile;

  return (
    <>
      <GoogleAnalytics />
      {_.isEmpty(postsSelected) ? (
        <NavBar showCloseIcon={showCloseIcon} />
      ) : (
        <MultiselectActionBar
          postsSelected={postsSelected}
          setPostsSelected={setPostsSelected}
        />
      )}
      <Switch>
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <ProtectedRoute
          exact
          path="/"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
        >
          <PostListContainer
            postsSelected={postsSelected}
            setPostsSelected={setPostsSelected}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path="/post/:id"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
          component={AnalyzePostPage}
        />
        <ProtectedRoute
          path="/add-facebook-post"
          userPassesTest={hasGroupTest('FacebookScraper')}
          deniedComponent={Unauthorized}
          component={FacebookFormContainer}
        />
      </Switch>
    </>
  );
};

export default Routes;
