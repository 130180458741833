import _ from 'lodash';
import { DEFAULT_COUNTRIES_BY_THEATER } from 'constants/index';

export const transformIncidents = (incidents) => {
  const expandedIncidents = expandIncidents(incidents);
  return expandedIncidents.map(formatIncident);
};

function expandIncidents(incidents) {
  return incidents.reduce((expandedIncidents, incident) => {
    let result;

    if (_.isEmpty(incident.locations)) {
      result = [
        { ...incident, locations: getDefaultLocation(incident.theater) },
      ];
    } else {
      result = _.map(incident.locations, (location) => ({
        ...incident,
        locations: [formatLocation(location)],
      }));
    }

    return [...expandedIncidents, ...result];
  }, []);
}

function formatIncident(incident) {
  const { incidentTime, incidentType } = incident;

  const incidentTypes = _.reduce(
    incidentType,
    (result, item) => {
      return [...result, ...item.id.split(',')];
    },
    []
  );

  return {
    ...incident,
    incidentType: _.uniq(incidentTypes),
    incidentTime: incidentTime?.dateString ? [incidentTime.dateString] : null,
  };
}

function getDefaultLocation(theater) {
  const defaultCountry = DEFAULT_COUNTRIES_BY_THEATER[theater];

  if (defaultCountry) {
    return [
      formatLocation({
        language: 'en',
        adminLevel0: defaultCountry,
      }),
    ];
  }

  return [];
}

function formatLocation(location) {
  // Doing this to convert from location API format to Blue Java Service
  return {
    language: 'en',
    admin0: location.adminLevel0 ?? null,
    admin1: location.adminLevel1 ?? null,
    admin2: location.adminLevel2 ?? null,
    admin3: location.adminLevel3 ?? null,
    admin4: location.adminLevel4 ?? null,
    admin5: location.adminLevel5 ?? null,
    admin6: location.adminLevel6 ?? null,
    pCode: location.pcode ?? null,
    description:
      location.location !== undefined && location.unknown === undefined
        ? location.location
        : null,
    latitude: location.lat ? parseFloat(location.lat) : null,
    longitude: location.lon ? parseFloat(location.lon) : null,
  };
}
