import React from 'react';
import { useMutation } from '@apollo/client';
import Toaster from 'utils/toasterConfig';
import { FacebookForm } from 'components/pages/FacebookForm';
import SUBMIT_FACEBOOK_POST from 'mutations/submitFacebookPost';
import { useIntl } from 'react-intl';

export const FacebookFormContainer = () => {
  const intl = useIntl();

  const [submitFacebookPost, { error, loading }] = useMutation(
    SUBMIT_FACEBOOK_POST,
    {
      onCompleted: () => {
        if (error) {
          Toaster.error(intl.formatMessage({ id: 'toasterFbError' }));
          return;
        }
        Toaster.success(intl.formatMessage({ id: 'toasterFbSuccess' }));
      },
    }
  );
  const handleSubmit = (post) => {
    submitFacebookPost({ variables: { post } });
  };
  return (
    <FacebookForm
      handleFacebookPost={handleSubmit}
      loading={loading}
      error={error}
    />
  );
};
