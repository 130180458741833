import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import createPersistedState from 'use-persisted-state';

// These files contain all of the text translations of what is used throughout the app.
// The flag (Syrian or British) at the top of the app ends up determining which json gets passed into the international provider
import messages_en from './translations/en.json';
import messages_ar from './translations/ar.json';

// Local storage for users fluent languages. This is sent with query for postList and user only receives post in languages they know
const useFluentLanguagesPersist = createPersistedState('attr-fluentLanguages');
// Local storage for users language they have set. Current languages are english and arabic. It will update the language of text used, alignment, date/time components.
const useLanguageSetPersist = createPersistedState('attr-languageSet');

const LanguageContext = React.createContext({});
const FluentLanguagesContext = React.createContext({});

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useLanguageSetPersist('en'); // default is set to english on first login, but will use local storage after it is set.
  const [fluentLanguages, setFluentLanguages] = useFluentLanguagesPersist([]); // default of no languages set. If no languages are sent, you will receive all posts.

  const messages = {
    ar: messages_ar,
    en: messages_en,
  };

  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      <FluentLanguagesContext.Provider
        value={{ fluentLanguages, setFluentLanguages }}
      >
        <IntlProvider
          messages={messages[locale]}
          locale={locale}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </FluentLanguagesContext.Provider>
    </LanguageContext.Provider>
  );
};

export const useLocale = () => useContext(LanguageContext);
export const useFluentLanguages = () => useContext(FluentLanguagesContext);
