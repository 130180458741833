import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  saveButtonRoot: {
    background: '#77B745',
    color: '#FFF',
    '&:hover': {
      background: '#70aa40',
    },
  },
}));
