import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { useButtonStyles } from '../FormStyles';
import { useStyles } from './AddIncidentButtonStyles';

export default function AddIncidentButton({ onClick = (f) => f }) {
  const buttonClasses = useButtonStyles();
  const addIncidentButtonClasses = useStyles();
  const intl = useIntl();

  return (
    <Button
      onClick={onClick}
      variant={'outlined'}
      classes={{ ...buttonClasses, ...addIncidentButtonClasses }}
      startIcon={<AddIcon />}
    >
      {intl.formatMessage({ id: 'addAnotherIncident' })}
    </Button>
  );
}
