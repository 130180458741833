import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

export const Heading = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: '#fafafa',
  },
}))(Typography);

export const MenuToolbar = withStyles((theme) => ({
  root: {
    position: 'sticky',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#000000',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    top: 0,
  },
}))(Toolbar);

export const useStyles = makeStyles((theme) => ({
  selectedCount: {
    fontSize: '20px',
  },
  submitIrrelevant: {
    fontSize: '14px',
    letterSpacing: '0.4px',
    color: '#fafafa',
  },
  countContainer: {
    margin: '16px 0px',
  },
}));
