import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ListItemText, ListItemIcon } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { mono } from 'styles/fonts';

import { InternalLink } from 'components/helpers';

// TODO - this should be stored on the theme in palette
import { COLORS } from 'styles/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ddd',
  },
  formRoot: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const StyledInternalLink = withStyles(() => {
  return {
    root: {
      '&:hover': {
        backgroundColor: COLORS.backgroundLight,
      },
    },
  };
})(InternalLink);

export const StyledListItemIcon = withStyles(() => ({
  root: {
    minWidth: '50px',
  },
}))(ListItemIcon);

const primary = {
  fontFamily: mono,
  fontSize: '.9rem',
};

export const StyledListItemText = withStyles((theme) => ({
  primary,
  secondary: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(ListItemText);

export const StyledToggleButton = withStyles((theme) => ({
  root: {
    border: 'none',
    backgroundColor: '#fafafa !important',
    paddingLeft: 0,
  },
}))(ToggleButton);
