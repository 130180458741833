import React from 'react';
import Box from '@material-ui/core/Box';

import RelevanceForm from '../RelevanceForm';
import AddIncidentButton from '../AddIncidentButton';
import IncidentForm from '../IncidentForm';
import IncidentForms from '../IncidentForms';

import { useQuestions } from 'components/QuestionsProvider';

export default function WizardContent({
  currentStep,
  relevanceAnswer,
  incidents,
  onRelevanceFormChange = (f) => f,
  onUpdateIncident = (f) => f,
  onDeleteIncident = (f) => f,
  onAddNewIncident = (f) => f,
}) {
  const [firstIncident, ...restOfIncidents] = incidents;
  const { questions } = useQuestions();

  if (currentStep.progress === questions.relevance.progress) {
    return (
      <RelevanceForm
        value={relevanceAnswer}
        onChangeValue={onRelevanceFormChange}
      />
    );
  }

  return (
    <>
      <IncidentForm
        data-testid="incident-form-0"
        incident={firstIncident}
        onChange={(updatedIncident) => onUpdateIncident(0, updatedIncident)}
      />
      <IncidentForms
        incidents={restOfIncidents}
        onChangeIncident={(index, updatedIncident) => {
          onUpdateIncident(index + 1, updatedIncident);
        }}
        onDeleteIncident={(index) => onDeleteIncident(index + 1)}
        renderLabelHeader={(index) => `${currentStep.header} ${index + 2}`}
      />
      <Box marginTop={1} />
      <AddIncidentButton onClick={onAddNewIncident} />
      <Box marginTop={1} />
    </>
  );
}
