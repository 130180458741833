export const COLORS = {
  backgroundDark: '#1F282B',
  backgroundLight: '#EEEEEE',
  disabledText: '#8D8E8F',
  error: '#F05659',
  onPrimary: '#000000',
  primaryDark: {
    main: '#D0F2B3',
    variant: '#81AA64',
    typeLight: '#FFFFFFDE',
    typeDark: '#FFFFFF61',
    typeDataCardDetail: '#FFFFFF99',
    typeDisabled: '#8D8E8F',
    blurBackground: '#00000061',
    borderColor: '#FFFFFF1F',
    onPrimary: '#000000',
  },
  primaryLight: {
    main: '#81AA64',
    variant: '#4F7838',
    typeLight: '#FFFFFFDE',
    typeDark: '#FFFFFF61',
    typeDisabled: '#8D8E8F',
  },
  typeLight: '#FFFFFFDE',
  typeDark: '#FFFFFF61',
};
