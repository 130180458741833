import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { useButtonStyles } from '../../FormStyles';
import { useStyles } from './SaveButtonStyles';

export default function SaveButton({
  isDisabled,
  loading,
  onClick = (f) => f,
}) {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const intl = useIntl();

  const saveButtonClasses = {
    outlined: buttonClasses.outlined,
    label: buttonClasses.label,
    contained: clsx(buttonClasses.contained, classes.saveButtonRoot),
  };

  return (
    <Button
      aria-label="saveReport"
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
      classes={saveButtonClasses}
      startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
    >
      {intl.formatMessage({ id: 'saveReport' })}
    </Button>
  );
}
