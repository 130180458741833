import React from 'react';
import { useIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { useQuestions } from 'components/QuestionsProvider';
import { useInputLabelStyles, useAutocompleteStyles } from '../../FormStyles';

export default function TypeQuestion({ values, onChange, isRequired }) {
  const inputLabelclasses = useInputLabelStyles();
  const autoCompleteclasses = useAutocompleteStyles();
  const { questions } = useQuestions();
  const incidentType = questions.incidents.items.incidentType;
  const intl = useIntl();

  return (
    <Autocomplete
      multiple
      options={incidentType.dropdown.options}
      value={values}
      renderOption={(option) => <React.Fragment>{option.text}</React.Fragment>}
      getOptionLabel={(option) => {
        return option.text;
      }}
      onChange={(e, value) => {
        onChange(value);
      }}
      getOptionSelected={(option, value) => value.id === option.id}
      classes={autoCompleteclasses}
      ChipProps={{ size: 'small' }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required={isRequired}
            helperText={
              isRequired ? `*${intl.formatMessage({ id: 'required' })}` : null
            }
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: inputLabelclasses,
            }}
            data-testid="incident-select"
            label={incidentType.dropdown.text}
          />
        );
      }}
    />
  );
}
