import React, { useState } from 'react';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useDrawerState } from './hooks/useDrawerState';
import { useStyles } from './SwipeableBottomDrawerStyles';

export default function SwipeableBottomDrawer({
  renderHeader = () => null,
  renderContent = () => null,
  renderFooter = () => null,
}) {
  const classes = useStyles();
  const {
    backdropRef,
    closeDrawerOnBackdropTouch,
    variant,
    isMobile,
    isFullScreen,
    checkIfFullScreen,
    isOpen,
    shouldForceOpen,
    setIsOpen,
  } = useDrawerState();

  const theme = useTheme();
  const [swipeAreaWidth, setswipeAreaWidth] = useState(0);

  const [isTapEvent, setIsTapEvent] = useState(null);

  return (
    <>
      <SwipeableDrawer
        variant={variant}
        disableDiscovery
        disableBackdropTransition
        disableSwipeToOpen={false}
        className={classes.drawer}
        open={shouldForceOpen}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperOpen]: shouldForceOpen,
            [classes.drawerPaperOpenFullScreen]: isFullScreen,
          }),
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true,
            children: <Box ref={backdropRef} width="100%" height="100%" />,
          },
          keepMounted: true,
          onClick: closeDrawerOnBackdropTouch,
          onTouchStart: closeDrawerOnBackdropTouch,
        }}
        anchor={isMobile ? 'bottom' : 'right'}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        swipeAreaWidth={swipeAreaWidth}
        SwipeAreaProps={{
          style: {
            height: `${swipeAreaWidth}px`,
            cursor: 'pointer',
          },
          onTouchStart: () => {
            setIsTapEvent(true);
          },
          onTouchMove: () => {
            setIsTapEvent(false);
          },
          onTouchEnd: () => {
            if (!isOpen && isTapEvent) {
              openDrawerAfterTouchAnimation();
              setIsTapEvent(false);
            }
          },
          onClick: () => setIsOpen(true),
        }}
      >
        {isMobile && isFullScreen && (
          <div className={classes.collapseButton}>
            <IconButton aria-label="collapse" onClick={() => setIsOpen(false)}>
              <KeyboardArrowDownIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
        <div
          ref={(e) => isMobile && checkIfFullScreen(e)}
          className={clsx(classes.drawerHeaderAndContent, {
            [classes.drawerHeaderAndContentOpen]: isOpen,
            [classes.drawerHeaderAndContentFullScreen]: isFullScreen,
          })}
        >
          <Box
            ref={calculateMarginTopForHeader}
            className={clsx(classes.drawerHeader, {
              [classes.drawerHeaderOpen]: shouldForceOpen,
            })}
            top={swipeAreaWidth * -1}
          >
            {isMobile && !isFullScreen && (
              <div className={classes.swipeLine}></div>
            )}
            {renderHeader()}
          </Box>

          <div>{renderContent()}</div>
        </div>
        <Box flex={1} />
        <div
          className={clsx(classes.drawerFooter, {
            [classes.drawerFooterFullScreen]: isFullScreen,
          })}
        >
          {renderFooter()}
        </div>
      </SwipeableDrawer>
    </>
  );

  function calculateMarginTopForHeader(el) {
    setswipeAreaWidth(el?.clientHeight);
  }

  function openDrawerAfterTouchAnimation() {
    setTimeout(() => {
      setIsOpen(true);
    }, theme.transitions.duration.leavingScreen);
  }
}
