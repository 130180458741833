import React from 'react';
import { useStyles } from './IncidentHeaderStyles';

export default function IncidentHeader({
  label,
  renderEndAdornment = (f) => f,
}) {
  const classes = useStyles();
  return (
    <div className={classes.wizardHeader}>
      <div className={classes.wizardHeaderLabel}>{label}</div>
      <div className={classes.wizardHeaderProgress}>{renderEndAdornment()}</div>
    </div>
  );
}
