import { makeStyles } from '@material-ui/core/styles';
const borderRadius = 16;
const boxShadow = '0px -8px 10px -5px rgba(0, 0, 0, 0.2)';
const drawerFooterHeight = '80px';
const drawerPaperPaddingTop = '28px';

export const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '320px',
      },
      flexShrink: 0,
    },
    drawerPaper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '320px',
        top: 'auto',
      },
      paddingTop: drawerPaperPaddingTop,
      paddingBottom: 0,
      overflow: 'visible',
    },
    drawerPaperOpen: {
      animation: `$preventFormClickOnOpenDrawerAnimation ${
        theme.transitions.duration.leavingScreen +
        theme.transitions.duration.enteringScreen
      }ms`,
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      [theme.breakpoints.up('sm')]: {
        borderRadius: 0,
      },
      boxShadow,
    },
    drawerHeaderAndContentOpen: {
      overflow: 'auto',
    },
    drawerHeaderAndContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxHeight: `calc(100vh - ${theme.overrides.MuiToolbar.dense.height} - ${drawerFooterHeight})`,
      [theme.breakpoints.up('sm')]: {
        maxHeight: `calc(100vh - ${theme.overrides.MuiToolbar.dense.height} - ${drawerFooterHeight} - ${drawerPaperPaddingTop})`,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
      },
    },
    drawerHeaderAndContentFullScreen: {
      paddingBottom: theme.spacing(2),
    },
    drawerFooter: {
      background: '#fff',
      height: drawerFooterHeight,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        position: 'fixed',
        bottom: 0,
        width: '320px',
        borderTop: '1px solid #E0E0E0',
      },
    },
    drawerFooterFullScreen: {
      borderTop: '1px solid #E0E0E0',
    },
    drawerHeader: {
      position: 'absolute',
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      visibility: 'visible',
      right: 0,
      left: 0,
      padding: theme.spacing(2),
      paddingBottom: '12px',
      background: '#fff',
      boxShadow,
      boxSizing: 'border-box',
      height: '84px',
    },
    drawerHeaderOpen: {
      position: 'initial',
      padding: 0,
      paddingBottom: '12px',
      boxShadow: 'none',
      height: 'auto',
    },
    drawerPaperOpenFullScreen: {
      overflow: 'hidden',
      zIndex: theme.zIndex.drawer + 1,
      borderRadius: 0,
      paddingTop: theme.overrides.MuiToolbar.dense.height,
      top: 0,
    },
    swipeLine: {
      height: '4px',
      width: '56px',
      background: '#E0E0E0',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      top: '8px',
      borderRadius: '5px',
    },
    collapseButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: theme.overrides.MuiToolbar.dense.height,
    },
    '@keyframes preventFormClickOnOpenDrawerAnimation': {
      '0%': {
        pointerEvents: 'none',
      },
      '100%': {
        pointerEvents: 'auto',
      },
    },
  };
});
